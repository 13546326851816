<template>
    <loader :active="loading"></loader>
    <loader-in :active="enter"></loader-in>

    <template v-if="!loading">
        <menu-top :view="view" :user="user" @event="filterEvent" :car="carObjets"></menu-top>
        <template v-if="cities.length > 0">
            <router-view v-slot="{ Component, route }"  @event="filterEvent">
                <transition :name="route.meta.transition" :mode="route.meta.mode">
                    <KeepAlive :include="['Menu', 'Home']" :max="10">
                        <component :is="Component" :con="con" :socket="socket" :lang="lang" :view="view" :transfer="transfer" :user="user" :cityMenu="selectedCity" :cities="cities" :car="carObjets" :lastPosition="lastPosition" class="font-1"/>
                    </KeepAlive>
                </transition>
            </router-view>
        </template>
        <footer-bottom :lang="lang" :user="user" :cityMenu="selectedCity" :cities="cities"></footer-bottom>
    </template>
</template>

<script>
import loader from "@/components/common/loaderApp.vue";
import loaderIn from "@/components/common/loaderIn.vue";
import auth from '@/assets/js/auth.js';
import  { request } from '@/assets/js/requests.js';
import  { socket } from '@/assets/js/socket.js';
import menuTop from "@/components/layout/header.vue";
import footerBottom from "@/components/layout/footer.vue";
//import auth from '@/assets/js/auth.js'

export default {
    name:"appGeneral",
    components:{
        menuTop,
        auth,
        loader,
        loaderIn,
        footerBottom
    },
    data(){
        return{
            enter:0,
            loading:true,
            lang:'es',
            view:'responsive',
            con:new request('https://ap15.efisense.net'),
            socket:new socket('wss://ap15.efisense.net', '9502'),
            user:{token:null,dataToken:null, data:{}, defIdentity:0},
            userDefIdentify:0,
            observer:null,
            observe:null,
            carObjets:{articles:{}, promos:[]},
            cities:[],
            lastPosition:{pos:0, module:null},
            selectedCity:localStorage.cityOrigin ? JSON.parse(localStorage.cityOrigin) : null,
            transfer:{
                to:null,
                data:null
            },
        }
    },
    mounted(){
        
        this.assignUniqueId().then((res)=>{
            this.getLogin(0, true).then((resLog)=>{
                if(resLog){
                    this.getCities(localStorage.crmAuthService).then((resCity)=>{
                        
                        this.setUserCity().then(()=>{
                            this.loading = false;
                            this.loadObserver();
                        })

                    }).catch((err)=>{err});
                }else{
                    this.getCities().then((resCity)=>{

                        this.setDefaultCitie().then(()=>{
                            this.loading = false;
                            this.loadObserver();
                            this.getCarInCloud();
                        })
                        
                    }).catch((err)=>{err});
                }
            }).catch((err)=>{err});
        }).catch((err)=>{err});


        /*this.getCities().then((res)=>{
            
            this.getLogin(0, true).then((res)=>{
                this.loadObserver();
                this.assignUniqueId().then((res)=>{
                    this.getCarInCloud();
                });
            });
        })*/
    },
    methods:{
        assignUniqueId(){ //paso 1
            return new Promise((resolve)=>{
                auth.getUnixserviceId().then((id)=>{
                    this.userDefIdentify = id;
                    this.user.defIdentity = this.userDefIdentify;
                    resolve(true)
                }).catch((err)=>{console.log('error id');resolve(true)})
            })
        },
        getLogin(time=1000, firstLoad=false){ //paso 2
            return new Promise((resolve)=>{
                this.enter = 1;
                let [dataToken, token, pass] =  auth.vigency(...auth.getDecodedJwt(...auth.existToken()));
                if(token && pass && dataToken){
                
                    setTimeout(()=>{
                        this.con.getUserData(token).then((res)=>{
                            
                            if(res.code == 200){
                                this.enter = 2;

                                if(this.socket.wsOn === false){ //activa ws par aseguimientos y mensajes
                                    this.socket.access = token;
                                    this.socket.activeWS();
                                }

                                //guardado de los datos de usuario
                                this.user = {'token':token, 'dataToken':dataToken, 'data':res.data, 'defIdentity': this.userDefIdentify};
                                let lengthCar = 0;

                                if (Array.isArray(this.carObjets.articles)) {
                                    lengthCar = this.carObjets.articles.length;
                                } else if (this.carObjets.articles && typeof this.carObjets.articles === 'object' && this.carObjets.articles.constructor === Object) {
                                    lengthCar = Object.keys(this.carObjets.articles).length;
                                }

                                if(lengthCar == 0){ //carrito
                                    this.getCarByUserJWT(); //obtiene el ultimo carrito en caso de no tener nada en la vista actual
                                }else{
                                    this.updateCarInCloud(); //sobreescribe el carrito que haya en la base de datos por usuario tener algo en memoria
                                }
                                
                                let transferData = { //mensaje a logincomponent
                                    to : ['loginLevels'],
                                    data : {
                                        recharge:new Date().getTime()
                                    }
                                }
                                this.transfer = transferData;

                                setTimeout(()=>{
                                    if(!firstLoad){
                                        this.setUserCity(false); //verificar ciudad o cambiar en caso de no corresponder
                                    }
                                    this.enter = 0;
                                    resolve(true)
                                }, 
                                time/2);
                                
                            }else{
                                resolve(true)
                            }
                        }).catch((err)=>{ this.filterEvent({type:'closeSession'});  resolve(true)})
                    }, time)
                }else{ //error en login por diversos temas
                    this.enter = 0;
                    this.user = {token:null,dataToken:null,data:{},defIdentity:this.userDefIdentify};
                    auth.removeLogin();
                    if(!firstLoad){ //si la funcion se ejecuto al abrir la pagina o por login desde formulario-seccion login
                        this.$router.replace({ path: '/' })
                    }
                    resolve(false)
                }
            })
        },
        getCities(token = null){ //paso 3
            return new Promise((resolve, reject)=>{
                this.con.getCities(token).then((res)=>{
                    if(res.code == 200){
                        this.cities = res.data;
                        resolve(true)
                    }else{
                        resolve(false)
                    }
                }).catch((err)=>{
                    reject()
                })
            }).catch((err)=>{ reject()})
        },
        getUserStoreId(){
            let homes = Object.keys(this.user.data.homes);
            if(homes.length == 1){
                return this.user.data.homes[homes[0]].storeNumber;
            }else if(this.user.data.hasOwnProperty('storeNumber')){
                return this.user.data.storeNumber;
            }
            return null
        },
        setUserCity(initial = true){ //paso 4
            return new Promise((resolve, reject)=>{
                let uStoreId = this.getUserStoreId();
                if(uStoreId != null){ // id encontrado
                    for(let i in this.cities){
                        for(let j in this.cities[i].stores){
                            if(j == uStoreId){
                                switch(initial){
                                    case true:
                                        this.selectedCity = this.cities[i];
                                        //console.log('cambiando a ciudad seleccionada:', this.cities[i].friendlyName)
                                        localStorage.setItem('cityOrigin', JSON.stringify(this.selectedCity));
                                        resolve(true);
                                    break;
                                    case false:
                                        if(this.selectedCity.friendlyName != this.cities[i].friendlyName){
                                            //cambiar solo si no es la misma ciudad puesta en memoria actualmente
                                            //console.log('remontando, cambiando a ciudad:', this.cities[i].friendlyName)
                                            this.filterEvent({type:'changeCity', city:this.cities[i]});
                                            return true;
                                        }
                                    break;
                                }
                                break;
                            }
                        }
                    }
                }else{
                    this.setDefaultCitie().then((res)=>{
                        resolve(false);
                    })
                }
            });
        },
        setDefaultCitie(){ //paso 4
            return new Promise((resolve, reject)=>{
                if(!localStorage.cityOrigin){ //si no existe un localstorage, definir una por defecto y guardar
                    this.selectedCity = this.cities[0];
                    localStorage.setItem('cityOrigin', JSON.stringify(this.selectedCity));
                    //console.log('ciudad por defecto:', this.cities[0].friendlyName)
                }else{ // si existe iterar las ciudades hasta encontrar la misma del localstorage y sobreescribirla (por tema de expiración jwt)
                    const citySelected = JSON.parse(localStorage.cityOrigin);
                    for(let i in this.cities){
                        if(this.cities[i].storeNumber == citySelected.storeNumber){
                            this.selectedCity = this.cities[i];
                            //console.log('ciudad ya seleccionada:', this.cities[i].friendlyName)
                            localStorage.setItem('cityOrigin', JSON.stringify(this.selectedCity));
                            break;
                        }
                    }
                }
                resolve(true)
            });
        },
        loadObserver(){ //paso 5
            this.observe = document.querySelectorAll("[observe]");
            this.observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            this.setOnAction(entry.target)
                        } else {
                            this.setOffAction(entry.target)
                        }
                    });
                },
                {
                    rootMargin: '-50% 0% -50% 0%',
                    treshold: 0
                }
            );
            this.observe.forEach((entry) => {
                this.observer.observe(entry);
            });
        },
        setOnAction(elm){
            switch(elm.getAttribute('observe')){
                case 'categoryContent':
                    let tranferData = {
                        to : ['MenuGeneral', 'MenuHome'],
                        data : elm
                    }
                    this.transfer = tranferData;
                    break;
                default:
                    break;
            }
        },
        setOffAction(elm){
            switch(elm.getAttribute('observe')){
                case 'categoryContent':
                    break;
                default:
                    break;
            }
        },
        getCarPricesPerStore(){
            this.con.getCar({id:'/'+this.user.defIdentity, token:this.selectedCity.token}).then((res)=>{
                if(res.code == 200 && res.data != '' && res.data != null){
                    this.carObjets.articles = JSON.parse(res.data.articles);
                    this.carObjets.promos = res.data.promos;
                    return true
                }
                this.carObjets.promos = [];
            }).catch((err)=>{});   
        },
        getCarByUserJWT(){ //paso 6
            if(this.user.token != null){
                this.con.getCar({id:'', token:this.user.token}).then((res)=>{
                    if(res.code == 200 && res.data != '' && res.data != null){
                        this.carObjets.articles = JSON.parse(res.data.articles);
                        this.carObjets.promos = res.data.promos;
                        return true
                    }
                    this.carObjets.promos = [];
                }).catch((err)=>{});
            }           
        },
        getCarInCloud(){ //paso 6
            this.con.getCar({id:'/'+this.user.defIdentity, token:(this.user.token != null ? this.user.token : this.selectedCity.token)}).then((res)=>{
                if(res.code == 200 && res.data != '' && res.data != null){
                    this.carObjets.articles = JSON.parse(res.data.articles);
                    this.carObjets.promos = res.data.promos;
                    return true
                }
                this.carObjets.promos = [];
            }).catch((err)=>{
                if(err.hasOwnProperty('request')){
                    if(err.request.status == 401){
                        this.filterEvent({type:'closeSession', noEraseCar:true});
                    }
                }else{
                    console.log(err); this.carObjets.articles = {}; this.carObjets.promos = [];
                }
            });            
        },
        updateCarInCloud(){
            this.con.saveCar({order:this.carObjets.articles, idFront:this.user.defIdentity}, (this.user.token != null ? this.user.token : this.selectedCity.token)).then((res)=>{
                if(res.code == 200){
                    if(res.data != null){
                        if(res.data.hasOwnProperty('promos')){
                            this.carObjets.promos = res.data.promos;
                            return true;
                        }
                    }
                }
                this.carObjets.promos=[];
            }).catch((err)=>{
                if(err.hasOwnProperty('request')){
                    if(err.request.status == 401){
                        this.filterEvent({type:'closeSession', noEraseCar:true});
                    }
                }else{
                    console.log(err);this.carObjets.promos=[];
                }
            });
        },
        filterEvent(data){
            switch(data.type){
                case "updateSession":
                    this.getLogin(data.time);
                    break;
                case "loginReplaceNew":
                    auth.getUnixserviceId(true).then((id)=>{
                        this.userDefIdentify = id; //nuevo id unique
                        this.user = {token:null,dataToken:null,data:{},defIdentity:this.userDefIdentify}; //reset de objeto user
                        auth.removeLogin(); //retiro de localstorage
                    }).catch((err)=>{console.log('error id')})
                    break;
                case "erasePreNumber":
                    this.user = {token:null,dataToken:null,data:{},defIdentity:this.userDefIdentify};
                    auth.removeLogin()
                    break;
                case "closeSession":
                    if(this.socket.wsOn === true){
                        this.socket.access = '';
                        this.socket.wsOn = false;
                        this.socket.forceCloseWS();
                    }
                    this.$router.push({ path: '/' })
                    auth.getUnixserviceId(true).then((id)=>{
                        this.userDefIdentify = id; //nuevo id unique
                        this.user = {token:null,dataToken:null,data:{},defIdentity:this.userDefIdentify}; //reset de objeto user
                        auth.removeLogin(); //retiro de localstorage
                        if(!data.hasOwnProperty('noEraseCar')){
                            this.carObjets = {articles:{}, promos:[]}; //borrado de carrito
                        }
                    }).catch((err)=>{console.log('error id')})
                    break;
                case "updateObserver":
                    this.loadObserver();
                    break;
                case "changeCity":
                    this.loading=true;
                    this.selectedCity = data.city;
                    localStorage.setItem('cityOrigin', JSON.stringify(data.city));
                    setTimeout(()=>{ //produce que se monten los componentes para recargar denuevo todos los enpoints con el token de la cidad
                        this.loading=false;
                    }, 2000)
                    break;
                case "lastPosition":
                    this.lastPosition = {...data};
                    break;

                case "rechargeCarWithStoreJWT":
                    this.getCarPricesPerStore();
                    break;

                case "editQuantityIntoCar":
                    for(let i in this.carObjets.articles){
                        for(let j in this.carObjets.articles[i]){
                            if(data.id == this.carObjets.articles[i][j].id){
                                this.carObjets.articles[i][j].quantity = data.quantity;
                                this.updateCarInCloud();
                                break;
                            }
                        }
                    }
                    break;

                case "editIntoCar":

                    for(let i in this.carObjets.articles){
                        for(let j in this.carObjets.articles[i]){
                            if(data.id == this.carObjets.articles[i][j].id){

                                this.carObjets.articles[i][j].options = data.data[0];
                                this.carObjets.articles[i][j].quantity = data.data[1];
                                this.carObjets.articles[i][j].price = data.data[2];
                                this.carObjets.articles[i][j].name = data.data[3];
                                this.carObjets.articles[i][j].catUrl = data.data[4];
                                this.carObjets.articles[i][j].baseId = data.data[5];
                                this.carObjets.articles[i][j].versionSelected = data.data[6];
                                this.carObjets.articles[i][j].image = data.data[7];
                                this.updateCarInCloud();

                                break;
                            }
                        }
                    }
                    break;
                case "addToCar":

                    let nElement = {id:new Date().getTime(), options:{}, price:0, quantity:0, name:"", catUrl:'', baseId:0, versionSelected:""};
                    nElement.options = data.data[0];
                    nElement.quantity = data.data[1];
                    nElement.price = data.data[2];
                    nElement.name = data.data[3];
                    nElement.catUrl = data.data[4];
                    nElement.baseId = data.data[5];
                    nElement.versionSelected = data.data[6];
                    nElement.image = data.data[7];

                    if(this.carObjets.articles.hasOwnProperty(data.data[8])){ //categoria existe?
                        this.carObjets.articles[data.data[8]].push(nElement);
                    }else{
                        this.carObjets.articles[data.data[8]] = [];
                        this.carObjets.articles[data.data[8]].push(nElement);
                    }

                    this.updateCarInCloud();

                    break;

                case "removeToCar":
                    for(let i in this.carObjets.articles){
                        for(let j in this.carObjets.articles[i]){
                            if(data.id == this.carObjets.articles[i][j].id){
                                this.carObjets.articles[i].splice(j, 1);
                                if(this.carObjets.articles[i].length == 0){
                                    delete this.carObjets.articles[i];
                                }
                                this.updateCarInCloud();
                                break;
                            }
                        }
                    }
                    break;
                case "removeCar":
                    this.carObjets = {articles:{}, promos:[]};
                    this.updateCarInCloud();
                    break;
            }
        }
    }
}
</script>

<style lang="scss">
    body{
        overflow-x: hidden;
        overflow-y: overlay;
        transition: margin-right 0.3s ease;
    }
    @media (max-width: 768px) {  
        body{
            width:100vw;
        }
    }
    body:not(.modalOpen){
        margin-right: 0px !important;
    }
    #app {
        position: relative;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        align-items: flex-start;
    }

    

    /* transiciones */

    .scale-enter-active,
    .scale-leave-active {
        transition: all 0.1s ease;
    }
    .scale-enter-from,
    .scale-leave-to {
        opacity: 0;
        transform: scale(0.9);
    }

    body::-ms-scrollbar {
        display: none;
    }
    body::-webkit-scrollbar {
        width: 10px;
    }
    body::-webkit-scrollbar-track {
        background: transparent;
    }
    body::-webkit-scrollbar-thumb {
        background: linear-gradient(45deg, #BE2A34, #c9525a, #BE2A34);
        height: 62px;
    }
    
</style>
