class socket {

	conUrl = "";
    conPort = "";
    access = "";
    ws = {};
    wsOn = false;
    onLine = navigator.onLine ? true : false;
    manualClosedWS = false;
    userData = {};
    tray = {
        destiny:[''],
        trayStatus:0,
    };
    trayStatus = 0;

	constructor(url, port) {
		this.conUrl = url;
        this.conPort = port;
	}

    activeWS() {
        //inicializar petitición websocket
        if(this.access != null && this.access != ''){
            try{
                this.ws = new WebSocket(this.conUrl + ':' + this.conPort + '?jwt=' + this.access);
                this.ws.createdID = new Date().getTime();
            }catch(err){console.log(err)}
            this.listenWS();
            return true
        }else{
            return false
        }
        
    }

    forceCloseWS() { //cierra conexion si existe
        try {
            this.wsOn = false;
            this.userData = {};
            this.manualClosedWS = true;
            this.ws.close();
            this.throwEvent(
                {
                    name:"closedWS",
                    destiny:['tracking', 'orderSign'],
                    trayStatus: this.trayStatus
                }
            ) 
        } catch (err) { }
    }

    manageErrWs(close = true, conectionID) { //cierra conexion y recarga

        if (close) {
            this.wsOn = false;
            this.userData = {};
            this.forceCloseWS();
        }

        this.timer(5000).then(() => {
            if(conectionID == this.ws.createdID){
                if (this.onLine && !this.wsOn) {
                    this.activeWS();
                } else {
                    if(!this.onLine || !this.wsOn){
                        this.onLine = navigator.onLine ? true : false;
                        this.manageErrWs(false, conectionID);
                    }
                }
            }
        })
    }

    listenWS() {
        //eventos de websocket directos

        this.ws.onerror = (err='') => {
            this.manageErrWs(true, this.ws.createdID);
        }

        this.ws.onmessage = (ev) => {
            if (this.wsOn) {
                let data = JSON.parse(ev.data);
                this.catchResponseFromWS(data);
            }
        }

        this.ws.onopen = () => {
            this.wsOn = true;
        }

        this.ws.onclose = (event) => {

            if (this.manualClosedWS) {
                this.wsOn = false;
                this.manualClosedWS = false;
            } else {
                this.wsOn = false;
                this.activeWS();
            }

        };

    }

    throwEvent(conf={}){
        ++this.trayStatus; //hace unico el evento
        conf.trayStatus = this.trayStatus;
        this.tray = conf; //ejectua evento al momento de cambiar
    }

    catchResponseFromWS(data) {
        //filtro de resultados de websocket
        if (data.hasOwnProperty('cmd') && data.hasOwnProperty('error')) {

            switch (data.cmd) {
                case 'newSesion':
                    this.userData.orders = [];
                    if(data.payload != false){
                        this.userData.orders.push(data.payload);
                    }
                    this.throwEvent(
                        {
                            name:"updateOrders",
                            destiny:['tracking', 'orderSign'],
                            trayStatus: this.trayStatus
                        }
                    ) 
                    break;
                case 'updateStatus':
                    this.userData.orders = [];
                    if(data.payload != false){
                        this.userData.orders.push(data.payload);
                    }
                    this.throwEvent(
                        {
                            name:"updateOrders",
                            destiny:['tracking', 'orderSign'],
                            trayStatus: this.trayStatus
                        }
                    ) 
                    break;
                default: ''
                    break;
            }

        }

    }

    timer(time) {
        //función de espera asíncrona
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(true);
            }, time);
        });
    }

}

export { socket };