<template>
    <div class="menu d-flex flex-wrap justify-content-center">

        <div class="w-100 ps-lg-5 d-flex flex-wrap align-items-center align-content-center justify-content-end">
            <div class="col-xxl-4 col-lg-6 col-12 col-lg-4 pe-lg-5">
                <div class="col-12 pe-lg-1">
                    <order-sign :socket="socket" :lang="lang"></order-sign>
                </div>
            </div>
        </div>

        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transition" :mode="route.meta.mode">
                <KeepAlive :include="['MenuGeneral']">
                    <component :is="Component" :socket="socket" :con="con" :view="view" :categories="categories" :transfer="transfer" :user="user" :car="car" :cityMenu="cityMenu" :lastPosition="lastPosition" class="font-1"/>
                </KeepAlive>
            </transition>
        </router-view>

    </div>
</template>

<script>
import orderSign from "@/components/common/orderSign.vue";

export default {
    name: "Menu",
    emits:['event'],
    components:{
        orderSign
    },
    props:{
        lang:{
            default:"es",
            type: String
        },
        cityMenu:{
            default:{},
            type: Object
        },
        cities:{
            default:[],
            type: Array
        },
        user:{
            type:Object,
            default:{}
        },
        socket:{
            default:{},
            type: Object
        },
        con:{
            default:{},
            type: Object
        },
        view:{
            default:"responsive",
            type: String
        },
        transfer:{
            type:Object,
            default:{}
        },
        lastPosition:{
            default:{pos:0, module:null},
            type:Object
        },
        car:{
            default:{},
            type:Object
        }
    },
    data(){
        return{
            categories:[],
            citySelectModal:false
        }
    },
    methods:{
        changeCity(){
            this.citySelectModal = true;
        },
        closeSelectCity(){
            this.citySelectModal = false;
        },
        getCategories(){
            return new Promise((resolve, reject) => {
                this.con.getCategories({token:this.cityMenu.token}).then((res)=>{
                    this.categories = res.data;
                    resolve(true)
                }).catch((err)=>{
                    reject(err)
                })
            })
            
        },
    },
    mounted(){
        this.getCategories();
    },
};
</script>

<style scoped>
    .menu{
        width: 100%;
        height: auto;
    }
</style>
