<template lang="html">
    <div id="accountContent" ref="accountContent" class="d-flex justify-content-center">
        <loader :active="loading"></loader>
        <modal-o-k :active="false" :mess="mess" :title="successTitle" :showIncrement="modalSuccess" :lang="lang"></modal-o-k>
        <modal-err :active="false" :mess="mess" :title="errorTitle" :showIncrement="modalError" :lang="lang"></modal-err>
        <modal-unhappy :active="false" :showIncrement="modalUnhapp" :lang="lang"></modal-unhappy>
        <div class="accountInner w-100 h-100 d-flex flex-wrap justify-content-center align-items-start align-content-start fitWidth overflow-hidden position-relative" v-if="filled">

            <template v-if="view == 'data'">
                <div class="w-100 d-flex justify-content-center text-start titled fw-bold font-4 mt-5">
                    <div class="col-xxl-7 col-lg-10 col-11 d-flex justify-content-start">
                        <span class="col-12 col-md-9 text-start" v-html="phrases.f5[lang]"></span>
                    </div>
                </div>
                <div class="w-100 d-flex justify-content-center text-start pointTitle font-9 mt-2">
                    <div class="col-xxl-7 col-lg-10 col-11 d-flex justify-content-start">
                        <span class="col-12 col-md-9 text-start ps-md-3">{{phrases.f10[lang]}}</span>
                    </div>
                </div>
                <div class="w-100 d-flex justify-content-center text-start my-5">
                    <div class="col-xxl-7 col-lg-10 col-11">
                        <div class="col-12 text-start ms-md-3 separator"></div>
                    </div>
                </div>
                <div class="w-100 d-flex flex-wrap justify-content-center">
                    <div class="col-xxl-7 col-lg-10 col-11 d-flex flex-wrap justify-content-center">
                        <div class="col-12 col-md-6 ps-md-5 ps-2 pe-2 pe-md-3">
                            <div class="col-12 subtitled font-7 text-start mb-3">{{phrases.f11[lang]}}*</div>
                            <div class="col-12 text-start d-flex justify-content-end ps-3">
                                <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"  id="nameAccount" name="nameAccount" @input="unMarkRed('nameAccount')" @change="unMarkRed('nameAccount')" v-model="form.name" :placeholder="phrases.f11[lang]" class="inputLogin w-100 px-3 font-7" maxlength="50" type="text">
                            </div>
                        </div>
                        <div class="col-12 col-md-6 pe-md-5 pe-2 ps-2 ps-md-3 mt-4 mt-md-0">
                            <div class="col-12 subtitled font-7 text-start mb-3">{{phrases.f12[lang]}}*</div>
                            <div class="col-12 text-start d-flex justify-content-end ps-3">
                                <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"  id="lastNameAccount" name="lastNameAccount"  @input="unMarkRed('lastNameAccount')"  @change="unMarkRed('lastNameAccount')" v-model="form.lastName" :placeholder="phrases.f12[lang]" class="inputLogin w-100 px-3 font-7" maxlength="50" type="text">
                            </div>
                        </div>
                        <div class="col-12 col-md-6 ps-md-5 ps-2 pe-2 pe-md-3 mt-4">
                            <div class="col-12 subtitled font-7 text-start mb-3">{{phrases.f14[lang]}}*</div>
                            <div class="col-12 text-start d-flex justify-content-end ps-3">
                                <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"  id="emailAccount" name="emailAccount"  @input="unMarkRed('emailAccount')" @change="unMarkRed('emailAccount')"  v-model="form.email" :placeholder="phrases.f15[lang]" class="inputLogin pushable w-100 px-3 font-7" maxlength="100" type="text" tabindex="-1">
                            </div>
                        </div>
                        <div class="col-12 col-md-6 pe-md-5 pe-2 ps-2 ps-md-3 mt-4">
                            <div class="col-12 subtitled font-7 text-start mb-3">{{phrases.f38[lang]}}*</div>
                            <div class="col-12 text-start d-flex justify-content-end ps-3">
                                <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"  id="numberAccount" name="numberAccount" @input="unMarkRed('numberAccount')"  @change="unMarkRed('numberAccount')" v-model="form.number" :placeholder="phrases.f3[lang]" class="inputLogin pe-none opacity-50 w-100 px-3 font-7" maxlength="10" type="text">
                            </div>
                        </div>
                        <div class="col-12 col-md-6 ps-md-5 ps-2 pe-2 pe-md-3 mt-5">
                            <div class="col-12 text-start d-flex justify-content-start ps-3 mt-5 mt-md-0">
                                <button class="accountButton pushable col-12 px-4 py-3 font-9" @click="closeSess()">{{phrases.f1[lang]}}</button>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 pe-md-5 pe-2 ps-2 ps-md-3 mt-md-5 mt-3">
                            <div class="col-12 text-start d-flex justify-content-start justify-content-md-end ps-3">
                                <button class="accountButton pushable active col-12 col-7 font-9 px-4 py-3" @click="saveAccountInfo()">{{phrases.f4[lang]}}</button >
                            </div>
                        </div>
                        <div class="col-12 ps-md-5 ps-2 pe-2 pe-md-5 mt-5">
                            <div class="col-12 subtitled font-7  text-start mb-3">{{phrases.f2[lang]}}</div>
                            <div class="col-12 text-start d-flex justify-content-end ps-3">
                                <div id="textareaSimulator" class="w-100 d-flex flex-wrap font-9 position-relative" @click="editAddress()">
                                    <template v-if="adresses[0].colony.value != '' && adresses[0].street.value != ''">
                                        <span class="colonyAddress">{{adresses[0].colony.value}}</span>&nbsp;- 
                                        &nbsp;<span class="linearAddress">
                                            {{adresses[0].street.value}}
                                            {{ (adresses[0].extNumber.value != '' ? '&nbsp;'+adresses[0].extNumber.value : '')}}
                                            {{ (adresses[0].intNumber.value != '' ? '&nbsp;'+adresses[0].intNumber.value : '')}}
                                            {{ (adresses[0].crossings.value != '' ? '&nbsp;'+adresses[0].crossings.value : '')}}
                                        </span>
                                        <div class="w-100">
                                            {{adresses[0].comment.value}}
                                        </div>
                                        <div class="editAccess font-8">{{phrases.f26[lang]}}</div>
                                    </template>
                                    <template v-else>
                                        {{phrases.f27[lang]}}
                                        <div class="editAccess font-8">{{phrases.f28[lang]}}</div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-else-if="view == 'dir'">
                <div class="w-100 d-flex justify-content-center text-start titled font-4 background-color: var(--primary);
                border: 2px solid var(--primary);
                color:var(--text2);
                filter: contrast(0.9); mt-5">
                    <div class="col-xxl-7 col-lg-10 col-11 d-flex justify-content-start">
                        <span class="col-12 col-md-9 text-start fw-bold" v-html="phrases.f7[lang]"></span>
                    </div>
                </div>
                <div class="w-100 d-flex justify-content-center text-start pointTitle font-9 mt-2">
                    <div class="col-xxl-7 col-lg-10 col-11 d-flex justify-content-start">
                        <span class="col-12 col-md-9 text-start ps-md-3">{{phrases.f10[lang]}}</span>
                    </div>
                </div>
                <div class="w-100 d-flex justify-content-center text-start my-5">
                    <div class="col-xxl-7 col-lg-10 col-11">
                        <div class="col-12 text-start ms-md-3 separator"></div>
                    </div>
                </div>
                <div class="w-100 d-flex flex-wrap justify-content-center mb-md-5">
                    <div class="col-xxl-7 col-lg-10 col-11 d-flex flex-wrap justify-content-center">

                        <div class="col-12 col-md-6 px-md-5 px-2 mt-4">
                            <div class="col-12 subtitled font-7 text-start mb-3">{{phrases.f16[lang]}}*</div>
                            <div class="col-12 text-start d-flex justify-content-end ps-3 position-relative">
                                <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" id="streetAccount" name="streetAccount" @input="unMarkRed('streetAccount')" @change="unMarkRed('streetAccount')" v-model="adresses[0].street.value" :placeholder="phrases.f17[lang]" class="inputAddress w-100 ps-3 font-7" maxlength="100" type="text">
                                <div class="superponIcon d-flex align-items-center align-content-center justify-content-center pe-3">
                                    <svg class="errorIcon" viewBox="0 0 180 180" width="100" height="100" v-if="adresses[0].street.error === true">
                                        <circle cx="90" cy="90" r="90" stroke-width="0" />
                                        <line x1="45" y1="45" x2="135" y2="135" stroke-width="12" />
                                        <line x1="45" y1="135" x2="135" y2="45" stroke-width="12" />
                                    </svg>
                                    <svg class="checkIcon" viewBox="0 0 180 180" v-else-if="adresses[0].street.error === false">
                                        <circle class="circled" cx="90" cy="90" r="90" stroke-width="3">
                                        </circle>
                                        <path d="M50 90 L80 120 L130 70" stroke-width="12" fill="transparent">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-6 px-md-5 px-2 mt-4">
                            <div class="col-12 subtitled font-7 text-start mb-3">{{phrases.f30[lang]}}*</div>
                            <div class="col-12 text-start d-flex justify-content-end ps-3 position-relative">
                                <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"  id="extNAccount" name="extNAccount" @input="unMarkRed('extNAccount'); alfanumericsData('adresses', 0, 'extNumber')" @change="unMarkRed('extNAccount')" v-model="adresses[0].extNumber.value" placeholder="145" class="inputAddress w-100 px-3 font-7" maxlength="5" type="text">
                                <div class="superponIcon d-flex align-items-center align-content-center justify-content-center pe-3">
                                    <svg class="errorIcon" viewBox="0 0 180 180" width="100" height="100" v-if="adresses[0].extNumber.error === true">
                                        <circle cx="90" cy="90" r="90" stroke-width="0" />
                                        <line x1="45" y1="45" x2="135" y2="135" stroke-width="12" />
                                        <line x1="45" y1="135" x2="135" y2="45" stroke-width="12" />
                                    </svg>
                                    <svg class="checkIcon" viewBox="0 0 180 180" v-else-if="adresses[0].extNumber.error === false">
                                        <circle class="circled" cx="90" cy="90" r="90" stroke-width="3">
                                        </circle>
                                        <path d="M50 90 L80 120 L130 70" stroke-width="12" fill="transparent">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-6 px-md-5 px-2 mt-4">
                            <div class="col-12 subtitled font-7 text-start mb-3">{{phrases.f31[lang]}}</div>
                            <div class="col-12 text-start d-flex justify-content-end ps-3 position-relative">
                                <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"  id="intNAccount" name="intNAccount" @input="unMarkRed('intNAccount'); alfanumericsData('adresses', 0, 'intNumber')" @change="unMarkRed('intNAccount')" v-model="adresses[0].intNumber.value" placeholder="145" class="inputAddress w-100 px-3 font-7" maxlength="5" type="text">
                                <div class="superponIcon d-flex align-items-center align-content-center justify-content-center pe-3">
                                    <svg class="errorIcon" viewBox="0 0 180 180" width="100" height="100" v-if="adresses[0].intNumber.error === true">
                                        <circle cx="90" cy="90" r="90" stroke-width="0" />
                                        <line x1="45" y1="45" x2="135" y2="135" stroke-width="12" />
                                        <line x1="45" y1="135" x2="135" y2="45" stroke-width="12" />
                                    </svg>
                                    <svg class="checkIcon" viewBox="0 0 180 180" v-else-if="adresses[0].intNumber.error === false">
                                        <circle class="circled" cx="90" cy="90" r="90" stroke-width="3">
                                        </circle>
                                        <path d="M50 90 L80 120 L130 70" stroke-width="12" fill="transparent">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-6 px-md-5 px-2 mt-4">
                            <div class="col-12 subtitled font-7 text-start mb-3">{{phrases.f32[lang]}}</div>
                            <div class="col-12 text-start d-flex justify-content-end ps-3 position-relative">
                                <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"  id="crossingsAccount" name="crossingsAccount" @input="unMarkRed('crossingsAccount')" @change="unMarkRed('crossingsAccount')" v-model="adresses[0].crossings.value" :placeholder="phrases.f33[lang]" class="inputAddress w-100 px-3 font-7" maxlength="100" type="text">
                                <div class="superponIcon d-flex align-items-center align-content-center justify-content-center pe-3">
                                    <svg class="errorIcon" viewBox="0 0 180 180" width="100" height="100" v-if="adresses[0].crossings.error === true">
                                        <circle cx="90" cy="90" r="90" stroke-width="0" />
                                        <line x1="45" y1="45" x2="135" y2="135" stroke-width="12" />
                                        <line x1="45" y1="135" x2="135" y2="45" stroke-width="12" />
                                    </svg>
                                    <svg class="checkIcon" viewBox="0 0 180 180" v-else-if="adresses[0].crossings.error === false">
                                        <circle class="circled" cx="90" cy="90" r="90" stroke-width="3">
                                        </circle>
                                        <path d="M50 90 L80 120 L130 70" stroke-width="12" fill="transparent">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-6 px-md-5 px-2 mt-4">
                            <div class="col-12 subtitled font-7 text-start mb-3">{{phrases.f9[lang]}}*</div>
                            <div class="col-12 text-start d-flex justify-content-end ps-3 position-relative">
                                <div class="filler font-7 ms-3"  v-show="choosingCity" ref="filler3">
                                    <div v-for="city in cities" class="py-2 colony ps-4" @click="setCity(city)">
                                        {{city.friendlyName}}
                                    </div>
                                </div>
                                <div 
                                    ref="filler4"
                                    id="cityAccount"
                                    name="cityAccount"
                                    @click="checkCities()" 
                                    @change="unMarkRed('cityAccount')" 
                                    v-text="referedCity" 
                                    class="inputAddress d-flex align-items-center align-content-center w-100 ps-3 font-7">
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-6 px-md-5 px-2 mt-4">

                            <div class="col-12 subtitled font-7 text-start mb-3 d-flex align-items-center align-content-center">
                                {{phrases.f18[lang]}}* 
                            </div>
                            <div class="col-12 text-start d-flex justify-content-end ps-3 position-relative">
                                <div class="filler font-7 ms-3" v-show="searchingColonies && colonies.length > 0" ref="filler">
                                    <div v-for="colony in colonies" class="py-2 colony ps-4" @click="setColony(colony)">
                                        {{colony.d_asenta}}&nbsp;-&nbsp;{{colony.d_codigo}}
                                    </div>
                                </div>
                                <input 
                                    ref="filler2"
                                    autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"  id="colonyAccount" name="colonyAccount"
                                    @click="getFillColonies()" 
                                    @input="checkColonies($event)" 
                                    @blur="checkColonyValid()"
                                    @change="unMarkRed('colonyAccount')" 
                                    v-model="referedColony" 
                                    :placeholder="phrases.f19[lang]" 
                                    class="inputAddress w-100 ps-3 font-7" 
                                    maxlength="60" 
                                    type="text">
                                    <div class="superponIcon d-flex align-items-center align-content-center justify-content-center pe-3">
                                        <svg class="errorIcon" viewBox="0 0 180 180" width="100" height="100" v-if="adresses[0].colony.error === true">
                                            <circle cx="90" cy="90" r="90" stroke-width="0" />
                                            <line x1="45" y1="45" x2="135" y2="135" stroke-width="12" />
                                            <line x1="45" y1="135" x2="135" y2="45" stroke-width="12" />
                                        </svg>
                                        <svg class="checkIcon" viewBox="0 0 180 180" v-else-if="adresses[0].colony.error === false">
                                            <circle class="circled" cx="90" cy="90" r="90" stroke-width="3">
                                            </circle>
                                            <path d="M50 90 L80 120 L130 70" stroke-width="12" fill="transparent">
                                            </path>
                                        </svg>
                                    </div>
                            </div>
                            
                        </div>  
                        <div class="col-12 px-md-5 px-2 mt-4">
                            <div class="col-12 subtitled font-7 text-start mb-3">{{phrases.f20[lang]}}*</div>
                            <div class="col-12 text-start d-flex justify-content-end ps-3 position-relative">
                                <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"  id="commentdirAccount" name="commentdirAccount" @input="unMarkRed('commentdirAccount')" @blur="checkInputValue('adresses', 0, 'comment', 'regComment', [0, 2])" @change="unMarkRed('commentdirAccount')" v-model="adresses[0].comment.value" :placeholder="phrases.f29[lang]" class="inputAddress w-100 px-3 font-7" maxlength="100" type="text">
                                <div class="superponIcon d-flex align-items-center align-content-center justify-content-center pe-3">
                                    <svg class="errorIcon" viewBox="0 0 180 180" width="100" height="100" v-if="adresses[0].comment.error === true">
                                        <circle cx="90" cy="90" r="90" stroke-width="0" />
                                        <line x1="45" y1="45" x2="135" y2="135" stroke-width="12" />
                                        <line x1="45" y1="135" x2="135" y2="45" stroke-width="12" />
                                    </svg>
                                    <svg class="checkIcon" viewBox="0 0 180 180" v-else-if="adresses[0].comment.error === false">
                                        <circle class="circled" cx="90" cy="90" r="90" stroke-width="3">
                                        </circle>
                                        <path d="M50 90 L80 120 L130 70" stroke-width="12" fill="transparent">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 px-md-5 px-2 mt-5">
                            <div class="col-12 text-start d-flex justify-content-start justify-content-md-end ps-3">
                                <button class="accountButton pushable col-12 col-7 font-9 px-4 py-3" @click="returnToData()">{{phrases.f8[lang]}}</button >
                            </div>
                        </div> 
                        <div class="col-12 col-md-6 px-md-5 px-2 mt-5 mb-md-5">
                            <div class="col-12 text-start d-flex justify-content-start justify-content-md-end ps-3">
                                <button class="accountButton pushable active col-12 col-7 font-9 px-4 py-3" @click="saveAddressInfo()">{{phrases.f6[lang]}}</button >
                            </div>
                        </div>

                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import loader from "@/components/common/loader.vue";
import modalOK from "@/components/common/successModal.vue";
import modalErr from "@/components/common/errorModal.vue";
import modalUnhappy from "@/components/common/unhappyModal.vue";

export default {
    name:"accountWizard",
    emits:['event'],
    components:{
        loader,
        modalOK,
        modalErr,
        modalUnhappy
    },
    props:{
        lang:{
            default:"es",
            type: String
        },
        user:{
            type:Object,
            default:{}
        },
        socket:{
            default:{},
            type: Object
        },
        con:{
            default:{},
            type: Object
        },
        transfer:{
            type:Object,
            default:{}
        },
        cityMenu:{
            default:{},
            type: Object
        },
        cities:{
            default:[],
            type: Array
        }
    },
    watch:{
        user(){
            this.accountNotEmpty();
        }
    },
    mounted(){
        this.accountNotEmpty();
        document.addEventListener('click', this.handleClick);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClick);
    },
    methods:{

        checkInputValue(array, index, key, reg='regText', results=[0]){
            if( results.includes(this.verify(this[reg], this[array][index][key].value)) ) {
                this[array][index][key].error = true;
            }else{
                this[array][index][key].error = false;
            }
        },

        alfanumericsData(array, index, key) {
            let value = this[array][index][key].value;
            value = value.replace(/[^a-zA-Z0-9]/g, '');
            this[array][index][key].value = value;
        },
        
        removeAllReds(){
            setTimeout(()=>{
                document.querySelectorAll('.RedFocus').forEach((elm)=>{
                    elm.classList.remove('RedFocus')
                })
            }, 1500)
        },
        getFillColonies(){
            setTimeout(()=>{
                this.searchingColonies = true;
            }, 50)
        },
        handleClick(event) {
            if(this.$refs.filler && this.$refs.filler2 && this.searchingColonies){
                if (!this.$refs.filler.contains(event.target) && !this.$refs.filler2.contains(event.target)) {
                    this.searchingColonies = false;
                }
            }
            if(this.$refs.filler3 && this.$refs.filler4 && this.choosingCity){
                if (!this.$refs.filler3.contains(event.target) && !this.$refs.filler4.contains(event.target)) {
                    this.choosingCity = false;
                }
            }
        },
        returnToData(){
            if(this.$route.query.fromCar){
                this.$router.replace({
                    name: 'order',
                    query: { fromDirection: true },
                });
            }else{
                this.view = 'data';
                this.accountNotEmpty();
                window.scrollTo({
                    top: this.$refs.accountContent.offsetTop -180,
                    behavior: 'instant'
                });
            }
        },
        accountNotEmpty(){
            if(Object.entries(this.user.data).length > 0){

                let dir1 = Object.keys(this.user.data.homes)[0];

                this.form.name = String(this.user.data.name),
                this.form.lastName = String(this.user.data.lastName),
                this.form.email = String(this.user.data.email),
                this.form.number = String(this.user.data.phone1)

            
                if(dir1 != undefined){
                    this.adresses[0].title = "casa";
                    this.adresses[0].street.value = this.user.data.homes[dir1].street || '';
                    this.adresses[0].extNumber.value = this.user.data.homes[dir1].extNumber || '';
                    this.adresses[0].intNumber.value = this.user.data.homes[dir1].intNumber || '';
                    this.adresses[0].crossings.value = this.user.data.homes[dir1].crossings || '';
                    this.adresses[0].colony.value = this.user.data.homes[dir1].colony || '';
                    this.adresses[0].typeColony = this.user.data.homes[dir1].typeColony || '';
                    this.referedCity = this.user.data.homes[dir1].city || '';
                    this.referedColony = this.user.data.homes[dir1].colony || '';
                    this.adresses[0].comment.value = this.user.data.homes[dir1].refer || '';
                    this.adresses[0].zip = this.user.data.homes[dir1].zip || '';
                    this.adresses[0].state = this.user.data.homes[dir1].state || '';
                    this.adresses[0].city = this.user.data.homes[dir1].city || '';
                    [this.adresses[0].stateCode, this.adresses[0].cityCode] = this.getLocationCodes(this.user.data.homes[dir1].city);
                }else{
                    this.adresses[0].title = 'casa';
                    this.referedCity = this.cityMenu.friendlyName;
                    this.adresses[0].state = this.cityMenu.state;
                    this.adresses[0].city = this.cityMenu.friendlyName;
                    [this.adresses[0].stateCode, this.adresses[0].cityCode] = this.getLocationCodes(this.cityMenu.friendlyName);
                }

                this.filled = true;
                this.loading = false;

                if(this.$route.query.fromCar){
                    if(this.pendingRelogin == false){
                        this.view = 'dir';
                    }else{
                        this.$router.replace({
                            name: 'order',
                            query: { fromDirection: true },
                        });
                    }
                }
            }
        },
        getLocationCodes(city=""){
            for(let i in this.cities){
                if(this.cities[i].friendlyName == city){
                    return [this.cities[i].cEstado, this.cities[i].cMnpio]
                }
            }
            return ["", ""]
        },
        formChange(){
            if(Object.entries(this.user.data).length > 0){
                try{
                    if(this.user.data.name != this.form.name || this.user.data.lastName != this.form.lastName || this.user.data.phone1 != this.form.number || this.user.data.email != this.form.email){
                        return true
                    }
                }catch(err){}
            }
            return false
        },
        dirChange(){
            if(Object.entries(this.user.data).length > 0){
                let dir1 = Object.keys(this.user.data.homes)[0];
                if(dir1 != undefined){
                    if(
                        this.user.data.homes[dir1].street != this.adresses[0].street.value 
                        || this.user.data.homes[dir1].intNumber != this.adresses[0].intNumber.value 
                        || this.user.data.homes[dir1].extNumber != this.adresses[0].extNumber.value 
                        || this.user.data.homes[dir1].crossings != this.adresses[0].crossings.value
                        || this.user.data.homes[dir1].colony != this.adresses[0].colony.value 
                        || this.user.data.homes[dir1].refer != this.adresses[0].comment.value){
                            return true
                    }else{
                        return false
                    }
                }
                return true
            }
            return false
        },
        checkCities(){
            this.choosingCity = true; //controlador de menu select
        },
        setCity(city={}){
            this.choosingCity = false;
            this.choosingColony = true;
            this.colonies = [];
            this.referedColony = "";
            this.adresses[0].colony.value = "";
            this.adresses[0].colony.error = "";
            this.referedCity = city.friendlyName;
            this.adresses[0].state = city.state;
            this.adresses[0].city = city.friendlyName;
            [this.adresses[0].stateCode, this.adresses[0].cityCode] = this.getLocationCodes(city.friendlyName);
            
            setTimeout(()=>{
                this.choosingColony = false;
            }, 500)
        },
        checkColonyValid(){
            if(this.adresses[0].colony.value == ""){
                this.referedColony = "";
            }
        },
        setColony(colony={}){

            this.choosingColony = true;
            this.referedColony = colony.d_asenta;

            if(colony.storeNumber == '' || colony.storeNumber == null || colony.storeNumber == 0){
                this.modalUnhapp++;
            }
            this.adresses[0].colony.error = false;
            this.adresses[0].storeNumber = colony.storeNumber;
            this.adresses[0].colony.value = colony.d_asenta;
            this.adresses[0].typeColony = colony.d_tipo_asenta;
            this.adresses[0].zip = colony.d_codigo;
            this.adresses[0].state = colony.d_estado;

            this.searchingColonies = false;
            setTimeout(()=>{
                this.choosingColony = false;
            }, 500)
        },
        checkColonies(e){
            this.adresses[0].colony.error = "";
            this.adresses[0].colony.value = "";

            if(!this.choosingColony && this.referedColony.length >= 3){
                let parsedSearch = this.adresses[0].stateCode+"/"+this.adresses[0].cityCode+"/"+this.referedColony;
                this.con.getColoniesMatch({token:this.cityMenu.token, url:parsedSearch}).then((res)=>{
                    if(res.code == 200){
                        this.colonies = res.data;
                        this.searchingColonies = true;
                    }
                }).catch((err)=>{})
            }
        },
        saveAccountInfo(){
            this.matchFormAccount().then((validate)=>{
                if(validate && !this.loading){
                    this.loading = true;
                    let request = {
                        token:this.user.token,
                        data:{
                            name:this.form.name,
                            lastName:this.form.lastName,
                            phone:this.user.data.phone1,
                            email:this.form.email
                        }
                    }
                    this.con.updateUser(request).then((res)=>{
                        this.loading = false;
                        if(res.code == 200){
                            this.successTitle = this.phrases.f22[this.lang];
                            this.mess = this.phrases.f23[this.lang];
                            this.modalSuccess += 1;
                            this.$parent.$emit('event', {type:'updateSession', time:0})
                        }else{
                            this.errorTitle = this.phrases.f25[this.lang];
                            this.mess = this.phrases.f24[this.lang] + ": " + res.msj;
                            this.modalError += 1;
                        }
                    }).catch((err)=>{
                        this.loading = false;
                        this.errorTitle = this.phrases.f21[this.lang];
                        this.mess = this.phrases.f24[this.lang];
                        this.modalError += 1;
                    })
                }
            }).catch((err)=>{this.loading = false;})
        },

        matchFormAccount(){

            return new Promise((resolve)=>{

                let val = true;

                if(!this.formChange()){
                    val = false;
                }

                if(this.verify(this.regNames, this.form.name) == 0 || this.verify(this.regNames, this.form.name) == 2) {
                    this.markRed("nameAccount");
                    val = false;
                }
                if(this.verify(this.regNames, this.form.lastName) == 0 || this.verify(this.regNames, this.form.lastName) == 2) {
                    this.markRed("lastNameAccount");
                    val = false;
                }
                /*if(this.verify(this.regPhone, this.form.number) == 0 || this.verify(this.regPhone, this.form.number) == 2) {
                    this.markRed("numberAccount");
                    val = false;
                }*/
                if(this.verify(this.regEmail, this.form.email) == 0 || this.verify(this.regNames, this.form.email) == 2) {
                    this.markRed("emailAccount");
                    val = false;
                }

                this.removeAllReds();

                if(!val){
                    resolve(val)
                }else{
                    resolve(val)
                }
            }).catch((err)=>{})         
        },
        saveAddressInfo(){
            this.matchFormAddress().then((validate)=>{
                if(validate && !this.loading){
                    this.loading = true;
                    let request = {
                        token:this.user.token,
                        data:{
                            "dwelling": this.adresses[0].title,
                            "street": this.adresses[0].street.value,
                            "extNumber": this.adresses[0].extNumber.value,
                            "intNumber": this.adresses[0].intNumber.value,
                            "crossings": this.adresses[0].crossings.value,
                            "colony": this.adresses[0].colony.value,
                            "typeColony": this.adresses[0].typeColony,
                            "city": this.adresses[0].city,
                            "zip": this.adresses[0].zip,
                            "state": this.adresses[0].state,
                            "refer": this.adresses[0].comment.value
                        }
                    }
                    this.con.saveUserAddress(request).then((res)=>{
                        this.loading = false;
                        if(res.code == 200){
                            this.pendingRelogin = true;
                            if(!this.$route.query.fromCar){
                                this.successTitle = this.phrases.f22[this.lang];
                                this.mess = this.phrases.f23[this.lang];
                                this.modalSuccess += 1;
                            }
                            this.view = 'data';
                            this.$parent.$emit('event', {type:'updateSession', time:0})
                        }else{
                            this.errorTitle = this.phrases.f21[this.lang];
                            this.mess = this.phrases.f24[this.lang] + ": " + (typeof res.msj == 'object' ? (JSON.stringify(res.msj)) : res.msj )
                            this.modalError += 1;
                        }
                    }).catch((err)=>{
                        this.loading = false;
                        this.errorTitle = this.phrases.f21[this.lang];
                        this.mess = this.phrases.f24[this.lang] ;
                        this.modalError += 1;
                    })
                }
            }).catch((err)=>{this.loading = false;})
        },
        matchFormAddress(){

            return new Promise((resolve, reject)=>{

                let val = true;

                if(!this.dirChange()){
                    val = false;
                }

                if(this.verify(this.regText2, this.adresses[0].street.value) == 0 || this.verify(this.regText2, this.adresses[0].street.value) == 2) {
                    this.adresses[0].street.error = true;
                    val = false;
                }else{
                    this.adresses[0].street.error = false;
                }
                if(this.verify(this.regAlphanumeric, this.adresses[0].extNumber.value) == 0 || this.verify(this.regAlphanumeric, this.adresses[0].extNumber.value) == 2) {
                    this.adresses[0].extNumber.error = true;
                    val = false;
                }else{
                    this.adresses[0].extNumber.error = false;
                }
                if(this.verify(this.regAlphanumeric, this.adresses[0].intNumber.value) == 0) {
                    this.adresses[0].intNumber.error = true;
                    val = false;
                }else{
                    this.adresses[0].intNumber.error = false;
                }
                if(this.verify(this.regText, this.adresses[0].crossings.value) == 0) {
                    this.adresses[0].crossings.error = true;
                    val = false;
                }else{
                    this.adresses[0].crossings.error = false;
                }
                if(this.adresses[0].colony.value == "" || this.adresses[0].colony.value == null) {
                    this.adresses[0].colony.error = true;
                    val = false;
                }
                if(this.verify(this.regComment, this.adresses[0].comment.value) == 0 || this.verify(this.regComment, this.adresses[0].comment.value) == 2) {
                    this.adresses[0].comment.error = true;
                    val = false;
                }else{
                    this.adresses[0].comment.error = false;
                }

                this.removeAllReds();

                if(!val){
                    resolve(val)
                }else{
                    resolve(val)
                }
            }).catch((err)=>{ console.log(err); return false })         
        },
        verify(reg, data){
            data = data.trim();
            if(data == null || data == ""){
                return 2
            }
            if(data.match(reg) == null){
                return 0
            }else{ 
                return 1
            }
        },
        closeSess(){
            this.$parent.$emit('event', {type:'closeSession'});
        },
        editAddress(){
            this.view = 'dir';
            window.scrollTo({
                top: this.$refs.accountContent.offsetTop -180,
                behavior: 'instant'
            });
        },
        markRed(domId){
            /**
             *
             * marca en rojo un input
             * @param domId: string, id del elemento dom asignarle el estilo
             * @change /
             * @return /
             */

            try{
                document.getElementById(domId).classList.add("RedFocus")
            }
            catch(err){}
        },
        unMarkRed(domId){
            /**
             *
             * desmarca en rojo un input
             * @param domId: string, id del elemento dom retirarle el estilo
             * @change /
             * @return /
             */

            try{
                document.getElementById(domId).classList.remove("RedFocus")
            }
            catch(err){}
        },
    },
    data(){
        return{
            view:this.$route.query.direction ? 'dir' : 'data',
            loading:true,
            filled:false,
            pendingRelogin:false,
            phrases:{
                f1:{
                    es:"Cerrar sesión",
                    en:"Close session"
                },
                f2:{
                    es:"Dirección de entrega",
                    en:"Delivery address"
                },
                f3:{
                    es:"10 dígitos",
                    en:"10 digits"
                },
                f4:{
                    es:"Actualizar información",
                    en:"Update information"
                },
                f5:{
                    es:"Información personal",
                    en:"Personal data"
                },
                f6:{
                    es:"Guardar dirección",
                    en:"Save address"
                },
                f7:{
                    es:"Información de entrega",
                    en:"Delivery info"
                },
                f8:{
                    es:"Regresar",
                    en:"Return"
                },
                f9:{
                    es:"Ciudad",
                    en:"City"
                },
                f10:{
                    es:"Campos obligatorios marcados con *",
                    en:"Personal data"
                },
                f11:{
                    es:"Nombre",
                    en:"Name"
                },
                f12:{
                    es:"Apellido",
                    en:"LastName"
                },
                f13:{
                    es:"Celular",
                    en:"Cellphone"
                },
                f14:{
                    es:"Correo electrónico",
                    en:"Email"
                },
                f15:{
                    es:"correo@email.com",
                    en:"mail@email.com"
                },
                f16:{
                    es:"Calle",
                    en:"Street"
                },
                f17:{
                    es:"Nombre de Calle",
                    en:"Street name"
                },
                f18:{
                    es:"Colonia",
                    en:"Colony"
                },
                f19:{
                    es:"Cólonia o código postal",
                    en:"Colony or zip code"
                },
                f20:{
                    es:"Instrucciones especiales",
                    en:"Special Instructions"
                },
                f21:{
                    es:"Guardado fallido",
                    en:"Save failed"
                },
                f22:{
                    es:"Datos de cuenta actualizados",
                    en:"Updated account details"
                },
                f23:{
                    es:"Su dirección ha sido actualizada correctamente",
                    en:"Your address has been successfully updated"
                },
                f24:{
                    es:"Los datos no ha podido guardarse",
                    en:"Data could not be saved:"
                },
                f25:{
                    es:"Su información ha sido actualizada correctamente",
                    en:"Your address has been successfully updated"
                },
                f26:{
                    es:"Editar",
                    en:"Edit"
                },
                f27:{
                    es:"No tienes una dirección añadida para tus pedidos",
                    en:"You do not have an address added for your orders"
                },
                f28:{
                    es:"Añadir",
                    en:"Add"
                },
                f29:{
                    es:"Portón negro",
                    en:"Black gate"
                },
                f30:{
                    es:"Número Exterior",
                    en:"Ext Number"
                },
                f31:{
                    es:"Número Interior",
                    en:"Int Number"
                },
                f32:{
                    es:"Cruzamientos",
                    en:"Crossings"
                },
                f33:{
                    es:"Por 3 y 5",
                    en:"12th Avenue and 14th Court"
                },
                f34:{
                    es:"Servicio para delivery disponible",
                    en:"Delivery service Available"
                },
                f36:{
                    es:"Servicio para recorger en sucursal disponible",
                    en:"Pickup at store Available"
                },
                f37:{
                    es:"País*",
                    en:"Country*"
                },
                f38:{
                    es:"Teléfono*",
                    en:"Cellphone*"
                },
            },
            referedColony:"",
            referedCity:"",
            colonies:[],
            searchingColonies:false,
            choosingColony:false,
            choosingCity:false,
            form:{
                name:'',
                lastName:'',
                email:'',
                number:''
            },
            adresses:[
                {
                    title:"",
                    street:{
                        value:"",
                        error:"",
                    },
                    extNumber:{
                        value:"",
                        error:"",
                    },
                    intNumber:{
                        value:"",
                        error:"",
                    },
                    crossings:{
                        value:"",
                        error:"",
                    },
                    colony:{
                        value:"",
                        error:"",
                    },
                    storeNumber:"",
                    zip:"",
                    state:"",
                    stateCode:"",
                    typeColony:"",
                    city:"",
                    cityCode:"",
                    comment:{
                        value:"",
                        error:0,
                    },
                }
            ],
            
            regNames:new RegExp(/^[a-zA-ZÀ-úñ ]{3,50}$/g),
            regText:new RegExp(/^[a-zA-ZÀ-úñ0-9 ]{3,50}$/g),
            regText2:new RegExp(/^[a-zA-ZÀ-úñ0-9 ]{1,50}$/g),
            regAlphanumeric:new RegExp(/^[a-zA-Z0-9]{1,50}$/g),
            regComment:new RegExp(/^[a-zA-ZÀ-úñ0-9()#., ]{5,150}$/g),
            regPhone:new RegExp(/^[0-9]{10,10}$/g),
            regEmail:new RegExp(/^[a-zA-Z0-9_.]{3,64}@[a-z0-9]{3,64}\.[a-z0-9]{2,10}(?:\.[a-z]{2,10}|)$/g),

            mess:'',
            successTitle:"",
            modalSuccess:0,
            errorTitle:"",
            modalError:0,
            modalUnhapp:0
        }
    }
}
</script>
<style lang="css" scoped>
    #accountContent{
        min-height: calc(100vh - 118px);
        min-width: 100vw;
    }
    .accountInner{
        padding-bottom: 150px;
        min-height: calc(100vh - 118px);
    }
    .errMess{
        color: var(--quinary);
    }
    .titled{
        color: var(--primary);
        line-height: 1.2;
        font-size: 45px;
    }

    .pointTitle{
        color: var(--text16);
        line-height: 1.2;
        font-size: 15px;
    }

    .subtitled{
        color: var(--dodecagenary);
        line-height: 1.2;
        font-size: 24px;
    }

    .inputLogin{
        min-height: 60px;
        border: 2px solid var(--ondecagenary);
        border-radius: 12px;
        font-size: 19px;
        outline: none;
    }

    .inputLogin::placeholder{
        font-size: 18px;
        letter-spacing: 0.1em;
        font-weight: 400;
        color: var(--text15);
        opacity: 0.5;
    }

    .inputAddress{
        min-height: 60px;
        border: 2px solid var(--ondecagenary);
        border-radius: 12px;
        padding-right: 70px;
        font-size: 19px;
        outline: none;
    }

    .inputAddress::placeholder{
        font-size: 18px;
        letter-spacing: 0.1em;
        font-weight: 400;
        color: var(--text15);
        opacity: 0.5;
    }

    .inputLogin:focus, .inputAddress:focus{
        border-color:var(--terdecagenary)
    }

    .accountButton{
        font-size: 18px;
        background-color: var(--tertiary);
        color:var(--text3);
        border: 2px solid var(--text12);
        outline: none;
        border-radius: 12px;
        pointer-events: none;
        transition: all 0.2s ease;
    }

    .accountButton.off{
        color:var(--text12);
    }

    .accountButton.pushable{
        pointer-events: all;
        cursor: pointer;
    }

    .accountButton.active{
        background-color: var(--primary);
        border: 2px solid var(--primary);
        color:var(--text2);
    }

    .accountButton.pushable:hover{
        background-color: var(--primary);
        border: 2px solid var(--primary);
        filter: contrast(0.9);
        color:var(--text2);
    }

    .accountButton.pushable:active{
        transform: scale(1);
        background-color: var(--quinary);
    }

    .accountButtonReset{
        font-size: 15px;
        background-color: var(--tertiary);
        color:var(--text12);
        outline: none;
        border: 2px solid var(--text12);
        border-radius: 12px;
    }

    .accountButtonReset:hover{
        background-color: var(--ondecagenary);
        color:var(--tertiary)
    }
    

    .separator{
        border-top:1px solid var(--ondecagenary);
        box-shadow: 0.5px 0.5px 0.5px rgba(200, 200, 200, 0.5);
    }

    #textareaSimulator{
        border: 2px solid var(--text12);
        border-radius: 7px;
        padding: 40px 35px;
        font-size: 18px;
        color:var(--sexadecagenary);
        cursor: pointer;
    }

    #textareaSimulator:hover{
        border:2px solid var(--primary);
        
    }

    .colonyAddress{
        font-weight: bold;
    }

    .editAccess{
        position: absolute;
        right: 20px;
        bottom: 10px;
        font-size: 19px;
        color: var(--primary);
        cursor: pointer;
    }
    .editAccess:hover{
        transform: scale(1.2);
    }

    .filler{
        position:absolute;
        top:105%;
        left:0;
        min-height:100px;
        max-height:300px;
        overflow-y:auto;
        overflow-x:hidden;
        width:calc(100% - 1rem);
        background-color:#444;
        color:#fff;
        font-size:18px;
        border-radius:12px;
        padding-bottom:15px;
        padding-top:5px;
        z-index: 1000;

    }
    .filler::-webkit-scrollbar {
        width: 12px; /* Ancho de la barra de desplazamiento */
    }

    .filler::-webkit-scrollbar-thumb {
        background-color: var(--primary); /* Color del "pulgón" de la barra de desplazamiento */
        border-radius: 12px; /* Borde redondeado del "pulgón" */
    }

    .filler::-webkit-scrollbar-track {
        background-color: transparent; /* Color de fondo de la barra de desplazamiento */
    }

    .colony{
        cursor:pointer;
        white-space:preline
    }

    .colony:hover{
        background-color:var(--primary)
    }

    .checkIcon, .errorIcon{
        width: 30px;
        height: 30px;
    }

    .checkIcon circle{
        fill:var(--septadecagenary)
    }
    .errorIcon circle{
        fill:var(--quinary)
    }
    .checkIcon path{
        stroke:#fff
    }
    .errorIcon line{
        stroke:#fff
    }

    .serviceLabel{
        font-size: 18px;
        height: 100%;
        color: var(--dodecagenary);
    }

    .superponIcon{
        position: absolute;
        right:0;
        top:0;
        height: 100%;
        width: 50px;
        z-index: 100;
    }
</style>